import React, { useState, useRef } from "react";
import Layout from "../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { addQuestion } from "../../services/quiz-api";
import "../css/login.css";
import "../css/custom.css";
import { levels } from "../../utils/levels";
import { types } from "../../utils/type";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import arrowBackIcon from '../icon/arrow-back.svg'; // Adjust the path as per your project structure
import { languages } from "../../utils/constants";


const AddQuestion = () => {
  const navigate = useNavigate();
  const { quizId } = useParams();
  const [previewContent, setPreviewContent] = useState(null);
  const [state, setState] = useState({
    title: "",
    type: "4",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    correctAnswer: "",
    levelId: levels[0].id,
    languageId: "0",
    quizId,
    image: "",
    video: "",
    question_id: ""
  });
  const formRef = useRef(null);
  // const [levels, setLevels] = useState([])

  const onSubmit = async (event, next = "") => {
    event.preventDefault();

    // setState({ ...state, title: '',option_1: '',option_2: '',option_3: '',option_4: '', correctAnswer: '', image: '',video: '' });
    // console.log(1)

    // return false;
    if (state.title) {
      await addQuestion({
        ...state
      })
        .then((res) => {

          if (next === 'next') {
            formRef.current.reset();
            setState(prevState => ({ ...prevState, title: '', option_1: '', option_2: '', option_3: '', option_4: '', correctAnswer: '', image: '', video: '', question_id: '', languageId: '' }));
          } else {
            console.log(res.id)
            setState(prevState => ({ ...prevState, question_id: res.id, title: '', option_1: '', option_2: '', option_3: '', option_4: '', languageId: '' }));
            toast("Data has been successfully inserted.")
          }
        })
        .catch((err) => {
          if (err.error) {
            toast(err.error)
          }
          console.log(err);
        });
    }
  };

  const handleRadioChange = (event) => {
    setState(prevState => ({ ...prevState, correctAnswer: event.target.value }));
  };
  // useEffect(() => {
  //     getAllLevel().then((res) => {
  //         setLevels(res)
  //         setState({
  //             ...state,
  //             type: "multiple-choice",
  //             levelId: res[0].id
  //         })
  //     }).catch(err => {
  //         console.log(err)
  //     })
  // }, [])
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (file.type.startsWith("image/")) {
          // If the file is an image, set the image state and preview image
          setState((prevState) => ({
            ...prevState,
            image: reader.result,
            video: "",
          }));
          state.image = reader.result;
          setPreviewContent(reader.result);
        } else if (file.type.startsWith("video/")) {
          // If the file is a video, set the video state and preview video
          setState((prevState) => ({
            ...prevState,
            video: reader.result,
            image: "",
          }));
          state.image = reader.result;
          setPreviewContent(reader.result);
        } else {
          // Handle other file types if needed
          console.error("Unsupported file type");
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const handleLanguageChange = (event) => {
    setState(prevState => ({
      ...prevState,
      languageId: event.target.value,
    }))
  };
  const handleTypeChange = (event) => {
    setState(prevState => ({
      ...prevState,
      type: event.target.value,
    }));
  };
  const handleLevelChange = (event) => {
    setState(prevState => ({
      ...prevState,
      levelId: event.target.value,
    }));
  };
  const handleClick = (event) => {
    onSubmit(event, 'next')
  };

  return (
    <>
      <ToastContainer />
      <Layout>
        <p onClick={() => navigate(-1)} className="back-pre"><img src={arrowBackIcon} alt="Back" /> Back</p>
        <div className="d-flex">

          <h2> Add Question</h2>
        </div>

        <div className="authincation-content">
          <section className="login-part">
            <div className="container">
              <div className="login">
                <div className="login-card">
                  <div className="card">
                    <form ref={formRef}>
                      <div className="card-body">
                        <div className="mb-4">
                          <label htmlFor="language" className="form-label">
                            Select Language
                          </label>
                          <select id="language" className="form-control" onChange={handleLanguageChange}>
                            {languages.map(language => (
                              <option value={language.id}>{language.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="title" className="form-label">
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            placeholder="Enter title"
                            value={state.title}
                            onChange={(event) =>
                              setState(prevState => ({ ...prevState, title: event.target.value }))
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="type" className="form-label">
                            Type
                          </label>
                          <div className="grid">
                            {types &&
                              types.map((type, index) => (
                                <div key={type.id}>
                                  <label>
                                    <input
                                      type="radio"
                                      name="type"
                                      value={type.id}
                                      onChange={handleTypeChange}
                                    />
                                    <span>
                                      {type.name}
                                    </span>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="levelId" className="form-label">
                            Level
                          </label>
                          <div className="grid">
                            {levels &&
                              levels.map((level, index) => (
                                <div key={level.id}>
                                  <label>
                                    <input
                                      type="radio"
                                      name="level"
                                      value={level.id}
                                      onChange={handleLevelChange}
                                    />
                                    <span>
                                      {level.name}
                                    </span>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="mb-4">
                          <label htmlFor="option_1" className="form-label">
                            Option 1
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="option_1"
                            placeholder="Enter option 1"
                            value={state.option_1}
                            onChange={(event) =>
                              setState(prevState => ({
                                ...prevState,
                                option_1: event.target.value,
                              }))
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="option_2" className="form-label">
                            Option 2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="option_2"
                            placeholder="Enter option 2"
                            value={state.option_2}
                            onChange={(event) =>
                              setState(prevState => ({
                                ...prevState,
                                option_2: event.target.value,
                              }))
                            }
                            autoComplete="off"
                          />
                        </div>
                        {String(state.type) === "4" && (
                          <>
                            <div className="mb-4">
                              <label htmlFor="option_3" className="form-label">
                                Option 3
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="option_3"
                                placeholder="Enter option 3"
                                value={state.option_3}
                                onChange={(event) =>
                                  setState(prevState => ({
                                    ...prevState,
                                    option_3: event.target.value,
                                  }))
                                }
                                autoComplete="off"
                              />
                            </div>
                            <div className="mb-4">
                              <label htmlFor="option_4" className="form-label">
                                Option 4
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="option_4"
                                placeholder="Enter option 4"
                                value={state.option_4}
                                onChange={(event) =>
                                  setState(prevState => ({
                                    ...prevState,
                                    option_4: event.target.value,
                                  }))
                                }
                                autoComplete="off"
                              />
                            </div>
                          </>
                        )}
                        <div className="mb-4 manage-option">
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              for="exampleRadios1"
                            >
                              <input
                                type="radio"
                                name="correctAnswer"
                                value="1"
                                checked={state.correctAnswer === "1"}
                                onChange={handleRadioChange}
                                className="form-check-input"
                                id="exampleRadios1"
                              />
                              Option 1
                            </label>
                          </div>

                          <div className="form-check">
                            <label
                              className="form-check-label"
                              for="exampleRadios2"
                            >
                              <input
                                type="radio"
                                name="correctAnswer"
                                value="2"
                                checked={state.correctAnswer === "2"}
                                onChange={handleRadioChange}
                                className="form-check-input"
                                id="exampleRadios2"
                              />
                              Option 2
                            </label>
                          </div>
                          {String(state.type) === "4" && (
                            <>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  for="exampleRadios3"
                                >
                                  <input
                                    type="radio"
                                    name="correctAnswer"
                                    value="3"
                                    checked={state.correctAnswer === "3"}
                                    onChange={handleRadioChange}
                                    className="form-check-input"
                                    id="exampleRadios3"
                                  />
                                  Option 3
                                </label>
                              </div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  for="exampleRadios4"
                                >
                                  <input
                                    type="radio"
                                    name="correctAnswer"
                                    value="4"
                                    checked={state.correctAnswer === "4"}
                                    onChange={handleRadioChange}
                                    className="form-check-input"
                                    id="exampleRadios4"
                                  />
                                  Option 4
                                </label>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="mb-4">
                          <label for="formFile" className="form-label">
                            Select File
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                            onChange={handleFileChange}
                          />
                          {previewContent && (
                            <div className="content-container">
                              {state.image && (
                                <img
                                  src={previewContent}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "200px",
                                    marginTop: "10px",
                                  }}
                                />
                              )}
                              {state.video && (
                                <video
                                  controls
                                  width="200"
                                  style={{ marginTop: "10px" }}
                                >
                                  <source
                                    src={previewContent}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="form-login-button pt-4 custom-width-next">
                          <button type="button" onClick={onSubmit}  >Save</button>
                          <button type="button" onClick={handleClick} value="next">Save & Next</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default AddQuestion;
